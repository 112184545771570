/*
  import { vendorWorkOrder } from '@/services/Vendors/VendorWorkOrders/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await vendorWorkOrder.dispatch ('getVendorWorkOrderList', {
      vendorID
    });
  */
  async getVendorWorkOrderList({}, { vendorID, hoaID }) {
    console.log('in getVendorWorkOrderList...')
    try {
      const result = await kms.get('/WorkOrders/WorkOrder/ListBasic', {
        params: {
          hoaID,
          vendorID
        }
      })

      if (isDebug == true) console.debug('getVendorWorkOrderList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The vendor work order list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const vendorWorkOrder = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
