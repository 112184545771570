import _get from 'lodash/get'
import { vendorWorkOrder } from '@/services/Vendors/VendorWorkOrders/store'
import { parseDate } from '@/utilities/Date/parse'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      var dateFormat = 'LL'

      const type = _get(entry, 'workOrderTypeName', '')
      const description = _get(entry, 'description', '')
      const locationDescription = _get(entry, 'locationDescription', '')
      const createdDate = parseDate(_get(entry, 'createdDate', null), dateFormat)
      const completedDate = parseDate(_get(entry, 'completionDate', null), dateFormat)

      return {
        type: type,
        description: description,
        locationDescription,
        createdDate: createdDate,
        completedDate: completedDate
      }
    })

    this.rows = rows
  },

  async loadVendorWorkOrders() {
    if (this.isDebug == true) console.debug('in loadVendorWorkOrders()...')

    await vendorWorkOrder
      .dispatch('getVendorWorkOrderList', {
        hoaID: this.hoaId,
        vendorID: this.vendorID
      })
      .then(({ list }) => {
        this.determineRows(list)
      })
  },

  reload() {
    this.loading = true

    this.vendorID = this.$route.params.id || 0

    this.rows = []
    this.loadVendorWorkOrders()

    this.loading = false
  }
}
